<template>
  <div :id="mapId"></div>
</template>

<script lang="ts">
import { LngLatLike, Map, NavigationControl, Marker } from "mapbox-gl";
import { PropType } from "vue";

const MARKER_WIDTH = "40px";
const MARKER_HEIGHT = "50px";

export default defineNuxtComponent({
  props: {
    center: {
      type: Object as PropType<LngLatLike>,
      required: true,
    },
    zoom: {
      type: Number as PropType<number>,
      default: 14,
    },
  },

  setup(props) {
    const mapId = "map";
    const { mapboxKey, brand } = useRuntimeConfig().public;
    const map = ref<Map>();
    const marker = ref<Marker>();

    function addMarker(coordinates: LngLatLike) {
      if (map.value) {
        const el = document.createElement("div");

        el.className = "marker";
        el.style.backgroundImage = `url(/image/mapMarker-${brand}.webp)`;
        el.style.width = MARKER_WIDTH;
        el.style.height = MARKER_HEIGHT;
        el.style.backgroundSize = "contain";
        el.style.backgroundRepeat = "no-repeat";

        marker.value = new Marker(el, {anchor: 'bottom',}).setLngLat(coordinates).addTo(map.value);
      }
    }

    onMounted(() => {
      if (mapboxKey) {
        map.value = new Map({
          accessToken: mapboxKey as string,
          container: mapId,
          style: "mapbox://styles/mapbox/streets-v12",
          center: props.center,
          zoom: props.zoom,
        });

        map.value?.addControl(new NavigationControl({ showCompass: false }));

        map.value.on("load", () => {
          addMarker(props.center);
        });
      }
    });

    return {
      mapId,
    };
  },
});
</script>
