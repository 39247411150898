<template>
  <section class="section contacts">
    <h2>{{ $t("Our Contacts") }}</h2>
    <div class="contacts-container">
      <div class="contacts-info">
        <div>
          <p class="text-h4">{{ $t("Phone") }}:</p>
          <a :href="`tel:${$t('contacts.phone')}`">{{
            $t("contacts.phone")
          }}</a>
        </div>
        <div v-if="officeContact.support">
          <p class="text-h4">{{ $t("Support phone") }}:</p>
          <a :href="`tel:${officeContact.support}`">{{
            officeContact.support
          }}</a>
        </div>
        <div>
          <p class="text-h4">{{ $t("Address") }}:</p>
          <p>{{ $t("contacts.address") }}</p>
        </div>
        <div>
          <p class="text-h4">{{ $t("Email") }}:</p>
          <a :href="`mailto:${officeContact.email}`">
            {{ officeContact.email }}
          </a>
        </div>
        <div>
          <p class="text-h4">{{ $t("Office hours") }}:</p>
          <p
            v-for="(time, index) in $tm('contacts.workTime')"
            :key="`worktime-${index}`"
          >
            {{ $rt(time) }}
          </p>
        </div>
      </div>
      <div class="contacts-map">
        <OfficeMap :center="officeContact.officeMarker" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { PropType } from "vue";

import OfficeMap from "~/components/map/OfficeMap.vue";
import { OfficeContact } from "~/types/content";

export default defineNuxtComponent({
  components: { OfficeMap },
  props: {
    officeContact: {
      type: Object as PropType<OfficeContact>,
      required: true,
    },
  },
});
</script>